
<form  [formGroup]="RTMForm" (ngSubmit)="save()">
  <div class="">
    <div><span class="text-20-bold-700 line-height-48">{{formHeading.formName}}</span></div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Medical practice: </span>
      <span class="text-20-bold-400">{{formHeading.clinicName}}</span>
    </div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Document created: </span>
      <span class="text-20-bold-400" *ngIf="formHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
      <span class="text-20-bold-400" *ngIf="formHeading.createdAt !== null">{{formHeading.createdAt | date: 'MMMM dd yyyy'}}</span>
    </div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Note : </span>
      <span class="text-20-bold-400">Please report the values your care team advised you to track. Questions with * are mandatory.</span>
    </div>
  </div>

<div class="mt-2">
<div class="wrap-box">
  <div>
    <label class="label-font-14">What date are you reporting for?</label>
  </div>
  <!-- <input type="date" class="custom-input" formControlName="reportingDate" [min]="minDate" (change)="onDateChange($event)"/> -->
  <mat-form-field floatLabel="never" class="mat-datepicker" (click)="picker.open()">
  <input matInput [matDatepicker]="picker" autocomplete="off" placeholder="MM/DD/YYYY" [value]="reportingDates.value" [min]="minDate" name="dateofbirth" (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
  <div *ngIf="formSubmitted && (RTMForm.get('reportingDate').invalid || RTMForm.get('reportingDate').touched)" class="text-danger">
    <span *ngIf="RTMForm.get('reportingDate').hasError('required')">
      Reporting date cannot be empty
    </span>
    </div>
</div>
</div>
<div class="mt-3">
<div class="wrap-box">
  <label class="label-font-14">What symptoms/side effects did you experience? Check all that applies.</label>
</div>
<div class="wrap-box" *ngFor="let symptom of symptomsList; let i = index">
  <div class="wrap-radio">
    <input type="checkbox" [id]="symptom.id" [value]="symptom.value" (change)="addSymptomsControls($event.target.checked, i); isOtherChecked()">
    <label [for]="symptom.id">{{ symptom.label }}</label>
  </div>
</div>
<div class="wrap-box">
  <input type="text" class="custom-input" formControlName="symptomsOther" [readonly]="symptomsOther" />
</div>
</div>

<div class="wrap-box" >
<h2 class="blue-header">Medication tracking</h2>
</div>

<div class="mt-3">
<div class="wrap-box">
    <label class="label-font-14">Did you take your daily meds?</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="takingMeds1" name="takingDailyMeds" formControlName="takingDailyMeds" [value]="true" >
      <label for="takingMeds1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="takingMeds2" name="takingDailyMeds" formControlName="takingDailyMeds" [value]="false" >
      <label for="takingMeds2">No</label>
    </div>
  </div>
  <div class="text-danger" *ngIf="formSubmitted && RTMForm.get('takingDailyMeds').value == null">
    <span>Please select any one option</span>
  </div>
</div>

<div class="mt-3">
<div class="wrap-box">
    <label class="label-font-14">How much relief (in percentage) did you get with 1 dose?</label>
  </div>
 <input type="number" class="custom-input custom-input-suffix ml-2" name="medsDozes" formControlName="medsDozes"/>
 <span class="custom-suffix">%</span>
 <div *ngIf="formSubmitted && (RTMForm.get('medsDozes').invalid || RTMForm.get('medsDozes').touched)" class="text-danger">
  <span *ngIf="RTMForm.get('medsDozes').hasError('required')">
    Minimum value allowed is 0
  </span>
  <span *ngIf="RTMForm.get('medsDozes').hasError('min')">
    Minimum value allowed is 0
  </span>
  <span *ngIf="RTMForm.get('medsDozes').hasError('max')">
    Maximum value allowed is up to 100
  </span>
</div>
</div>

<div class="mt-3">
  <div class="wrap-box">
      <label class="label-font-14">How long (in hours) does your medication work?</label>
    </div>
   <input type="number" class="custom-input" name="medicationWork" formControlName="medicationWork"/>
   <div *ngIf="formSubmitted && (RTMForm.get('medicationWork').invalid || RTMForm.get('medicationWork').touched)" class="text-danger">
    <span *ngIf="RTMForm.get('medicationWork').hasError('required')">
      Minimum value allowed is 0
    </span>
    <span *ngIf="RTMForm.get('medicationWork').hasError('min') ">
      Minimum value allowed is 0
    </span>
    <span *ngIf="RTMForm.get('medicationWork').hasError('max')">
      Maximum value allowed is up to 24
    </span>
  </div>
</div>

<div class="mt-3">
<div class="wrap-box">
    <label class="label-font-14">How often are you needing to take your pain medicine per day?</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="painMedicine1" name="painMedicine" formControlName="painMedicine" value="NONE" required>
      <label for="painMedicine1">None</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="painMedicine2" name="painMedicine" formControlName="painMedicine" value="ONCE" required>
      <label for="painMedicine2">Once</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="painMedicine3" name="painMedicine" formControlName="painMedicine" value="TWICE" required>
      <label for="painMedicine3">Twice</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="painMedicine4" name="painMedicine" formControlName="painMedicine" value="THREE_TIMES" required>
      <label for="painMedicine4">Three times</label>
    </div>
  </div>
  <div class="text-danger" *ngIf="formSubmitted && (RTMForm.get('painMedicine').value == '' || RTMForm.get('painMedicine').value == null)">
    <span>Please select any one option</span>
  </div>
</div>

<div class="mt-3" >
<div class="wrap-box">
    <label class="text-20-bold-600 label-font-14">Have you been more active while on meds?</label>
</div>
<div class="wrap-box">
  <div class="wrap-radio">
    <input type="radio" name="activeOnMeds" id="activeOnMeds1" [value]="true" formControlName="activeOnMeds">
    <label for="activeOnMeds1">Yes</label>
  </div>
</div>
<div class="wrap-box">
  <div class="wrap-radio">
    <input type="radio" name="activeOnMeds" id="activeOnMeds2" [value]="false"  formControlName="activeOnMeds">
    <label for="activeOnMeds2">No</label>
  </div>
</div>
<div class="text-danger" *ngIf="formSubmitted && RTMForm.get('activeOnMeds').value == null">
  <span>Please select any one option</span>
</div>
</div>

<div class="mt-3" >
<div class="wrap-box">
    <label class="text-20-bold-600 label-font-14">What in particular can you do more of now with meds that you couldn’t do before without meds?</label>
</div>
<input type="text" class="custom-input" name="canDoMoreWithMeds" formControlName="canDoMoreWithMeds">
</div>
<div class="mt-2" >
  <div class="wrap-box">
      <label class="text-20-bold-600 label-font-14">Do your crave opioid pain meds or always think about them?</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="craveOpioid" id="craveOpioid1" [value]="true" formControlName="craveOpioid">
      <label for="craveOpioid1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="craveOpioid" id="craveOpioid2" [value]="false"  formControlName="craveOpioid">
      <label for="craveOpioid2">No</label>
    </div>
  </div>
  </div>
  <div class="mt-2" >
    <div class="wrap-box">
        <label class="text-20-bold-600 label-font-14">Do you feel your pain is:</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="feelPain" id="feelPain1" value="WELL_CONTROLLED" formControlName="feelPain">
        <label for="feelPain1">Well controlled</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="feelPain" id="feelPain2" value="SOMEWHAT_CONTROLLED"  formControlName="feelPain">
        <label for="feelPain2">Somewhat controlled</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="feelPain" id="feelPain3" value="NOT_CONTROLLED"  formControlName="feelPain">
        <label for="feelPain3">Not controlled</label>
      </div>
    </div>
    <div class="text-danger" *ngIf="formSubmitted && (RTMForm.get('feelPain').value == null || RTMForm.get('feelPain').value == '')">
      <span>Please select any one option</span>
    </div>
    </div>
    <div class="mt-2" >
      <div class="wrap-box">
          <label class="text-20-bold-600 label-font-14">Did you have a recent procedure?</label>
      </div>
      <div class="wrap-box">
        <div class="wrap-radio">
          <input type="radio" name="recentProcedure" id="recentProcedure1" [value]="true" formControlName="recentProcedure">
          <label for="recentProcedure1">Yes</label>
        </div>
      </div>
      <div class="wrap-box">
        <div class="wrap-radio">
          <input type="radio" name="recentProcedure" id="recentProcedure2" [value]="false"  formControlName="recentProcedure">
          <label for="recentProcedure2">No</label>
        </div>
      </div>
      </div>
      <div class="mt-2" >
        <div class="wrap-box">
            <label class="text-20-bold-600 label-font-14">Do you feel the meds change your mood, for worse?</label>
        </div>
        <div class="wrap-box">
          <div class="wrap-radio">
            <input type="radio" name="feelMeds" id="feelMeds1" [value]="true" formControlName="feelMeds">
            <label for="feelMeds1">Yes</label>
          </div>
        </div>
        <div class="wrap-box">
          <div class="wrap-radio">
            <input type="radio" name="feelMeds" id="feelMeds2" [value]="false"  formControlName="feelMeds">
            <label for="feelMeds2">No</label>
          </div>
        </div>
        </div>
        <div class="mt-3" >
          <div class="wrap-box">
              <label class="text-20-bold-600 label-font-14">Additional Comments</label>
              <input type="text" class="custom-input" name="additionalComments" formControlName="additionalComments">
          </div>
          </div>

        <div class="wrap-box">
          <button class="btn-save">Submit</button>
        </div>
  </form>
