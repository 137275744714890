<form  [formGroup]="dailyAsthmaForm" (ngSubmit)="save()">
    <div class="">
      <div><span class="text-20-bold-700 line-height-48">{{formHeading.formName}}</span></div>
      <div class="line-height-48">
        <span class="text-20-bold-700">Medical practice: </span>
        <span class="text-20-bold-400">{{formHeading.clinicName}}</span>
      </div>
      <div class="line-height-48">
        <span class="text-20-bold-700">Document created: </span>
        <span class="text-20-bold-400" *ngIf="formHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
        <span class="text-20-bold-400" *ngIf="formHeading.createdAt !== null">{{formHeading.createdAt | date: 'MMMM dd yyyy'}}</span>
      </div>
    </div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Note : </span>
      <span class="text-20-bold-400" >Please report the values your care team advised you to track. Questions with * are mandatory.</span>
    </div>


  <div class="wrap-box" >
    <h2 class="blue-header">General</h2>
  </div>

<div class="mt-2">
  <div class="wrap-box">
    <div>
      <label class="label-font-14">For what date are you reporting?</label>
    </div>
    <!-- <input type="date" class="custom-input" formControlName="reportingDate" [min]="minDate" (change)="onDateChange($event)"/> -->

    <mat-form-field floatLabel="never" class="mat-datepicker" (click)="picker.open()">
      <input matInput [matDatepicker]="picker" autocomplete="off" placeholder="MM/DD/YYYY" [value]="reportingDates.value" [min]="minDate" name="reportingDate" (dateChange)="onDateChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
</div>
<div class="mt-3">
  <div class="wrap-box">
    <label class="label-font-14">What symptoms did you experience? Check all that applies.</label>
</div>
  <div class="wrap-box" *ngFor="let symptom of symptomsList; let i = index">
    <div class="wrap-radio">
      <input type="checkbox" [id]="symptom.id" [value]="symptom.value" (change)="addSymptomsControls($event.target.checked, i); isOtherChecked()">
      <label [for]="symptom.id">{{ symptom.label }}</label>
    </div>
  </div>
  <div class="wrap-box">
    <input type="text" class="custom-input" [readonly]="isOtherFreeText" />
  </div>
</div>
<div class="mt-3">
  <div class="wrap-box">
      <label class="label-font-14">Triggers</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" maxlength="500" name="symptomsOther" formControlName="symptomsOther">
    </div>
</div>

<div class="mt-3">
  <div class="wrap-box">
      <label class="label-font-14">How would you rate your symptom changes?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="symptomsrate1" name="symptomsRate" formControlName="symptomsRate" value="BETTER">
        <label for="symptomsrate1">Better</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="symptomsrate2" name="symptomsRate" formControlName="symptomsRate" value="THE_SAME">
        <label for="symptomsrate2">The same</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="symptomsrate3" name="symptomsRate" formControlName="symptomsRate" value="WORSE">
        <label for="symptomsrate3">Worse</label>
      </div>
    </div>
</div>

<div class="wrap-box" >
  <h2 class="blue-header">Medication tracking</h2>
</div>

<div class="mt-3">
  <div class="wrap-box">
      <label class="label-font-14">Did you take your daily preventative meds?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="takingmeds1" name="takingDailyPreventativeMeds" formControlName="takingDailyPreventativeMeds" [value]="true" >
        <label for="takingmeds1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="takingmeds2" name="takingDailyPreventativeMeds" formControlName="takingDailyPreventativeMeds" [value]="false" >
        <label for="takingmeds2">No</label>
      </div>
    </div>
    <div class="text-danger" *ngIf="formSubmitted && dailyAsthmaForm.get('takingDailyPreventativeMeds').value === null">
      <span>Please select one option</span>
    </div>
</div>

<div class="mt-3">
  <div class="wrap-box">
      <label class="label-font-14">How many dozes did you use during the day?</label>
    </div>
    <div class="row">
   <input type="number" class="custom-input custom-input-suffix2 ml-2" name="preventativeMedsDozes" formControlName="preventativeMedsDozes"/>
  <span class="suffix-text custom-suffix">dozes/puffs in the day</span>
</div>
<div *ngIf="dailyAsthmaForm.get('preventativeMedsDozes').invalid && (dailyAsthmaForm.get('preventativeMedsDozes').dirty || dailyAsthmaForm.get('preventativeMedsDozes').touched)" class="text-danger">
  <span *ngIf="dailyAsthmaForm.get('preventativeMedsDozes').hasError('min')">
    Minimum value allowed is 0.
  </span>
  </div>
</div>

 <div class="mt-3">
  <div class="wrap-box">
    <div>
      <label class="label-font-14">How frequently?</label>
    </div>
    <select class="custom-input" name="preventativeMedsFrequency" formControlName="preventativeMedsFrequency">
      <option value="">Select One</option>
      <option value="0" >0</option>
      <option value="1" >1</option>
      <option value="2" >2</option>
      <option value="3" >3</option>
      <option value="4" >4</option>
      <option value="5" >5</option>
      <option value="6" >6</option>
      <option value="7" >7</option>
      <option value="8" >8</option>
      <option value="9" >9</option>
      <option value="10" >10</option>
      <option value="11" >11</option>
      <option value="12" >12</option>
      <option value="13" >13</option>
      <option value="14" >14</option>
      <option value="15" >15</option>
      <option value="16" >16</option>
      <option value="17" >17</option>
      <option value="18" >18</option>
      <option value="19" >19</option>
      <option value="20" >20</option>
    </select>
  </div>
 </div>

 <div class="mt-3">
  <div class="wrap-box">
      <label class="label-font-14">Did you use your quick-relief inhaler?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="quickrelief1" name="quickReliefInhalerUsed" formControlName="quickReliefInhalerUsed" [value]="true" required>
        <label for="quickrelief1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="quickrelief2" name="quickReliefInhalerUsed" formControlName="quickReliefInhalerUsed" [value]="false" required>
        <label for="quickrelief2">No</label>
      </div>
    </div>
    <div class="text-danger" *ngIf="formSubmitted && dailyAsthmaForm.get('quickReliefInhalerUsed').value === null">
      <span>Please select one option</span>
    </div>
</div>

<div class="wrap-box" >
  <h2 class="blue-header">Activity</h2>
</div>
<div class="mt-3" >
  <div class="wrap-box">
      <label class="text-20-bold-600 label-font-14">What were your activity levels on the day?</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="activityLevels" id="activityLevels1" value="LOW_LEVELS" formControlName="activityLevels">
      <label for="activityLevels1">Low level activities (slow walk or stretching)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="activityLevels" id="activityLevels2" value="MODERATE_LEVELS"  formControlName="activityLevels">
      <label for="activityLevels2">Moderate levels (brisk walking, low impact exercise) </label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="activityLevels" id="activityLevels3" value="HIGH_INTENSITY"  formControlName="activityLevels">
      <label for="activityLevels3">High intensity activities (run or high impact exercise)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="activityLevels" id="activityLevels4" value="MISSED_ACTIVITIES"  formControlName="activityLevels">
      <label for="activityLevels4">I missed or avoided activities due to </label>
    </div>
  </div>
</div>

<div class="mt-3" >
  <div class="wrap-box">
      <label class="text-20-bold-600 label-font-14">How did you sleep the prior night?</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="sleepQuality" id="sleepQuality1" value="NO_WALKING_NO_WHEEZING" formControlName="sleepQuality" required>
      <label for="sleepQuality1">No waking, no wheezing, no coughing</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="sleepQuality" id="sleepQuality2" value="SLEPT_WELL_SLIGHT_WHEEZE"  formControlName="sleepQuality" required>
      <label for="sleepQuality2">Slept well, slight wheeze or cough</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="sleepQuality" id="sleepQuality3" value="WOKE_UP_WHEEZE"  formControlName="sleepQuality" required>
      <label for="sleepQuality3">Woke up 2-3 times, wheeze or cough</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" name="sleepQuality" id="sleepQuality4" value="BAD_NIGHT"  formControlName="sleepQuality" required>
      <label for="sleepQuality4">Bad night, wake most of the time</label>
    </div>
  </div>
  <div class="text-danger" *ngIf="formSubmitted && (dailyAsthmaForm.get('sleepQuality').value === '' || dailyAsthmaForm.get('sleepQuality').value === null)">
    <span>Please select one option</span>
  </div>
</div>

<div class="wrap-box" >
  <h2 class="blue-header">Results</h2>
</div>
<div class="mt-2">
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Spirometer test - FEV1</label>
    </div>
    <div class="row">
   <input type="number" class="custom-input custom-input-suffix ml-2"  name="spirometerFEV1" formControlName="spirometerFEV1" (ngModelChange)="calculateRatio()"/>
   <span class="custom-suffix">L/min</span>
  </div>
  <div *ngIf="dailyAsthmaForm.get('spirometerFEV1').invalid && (dailyAsthmaForm.get('spirometerFEV1').dirty || dailyAsthmaForm.get('spirometerFEV1').touched)" class="text-danger">
    <span *ngIf="dailyAsthmaForm.get('spirometerFEV1').hasError('min')">
      Minimum value allowed is 0.
    </span>
    </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Spirometer test - FVC</label>
    </div>
    <div class="row">
   <input type="number" class="custom-input custom-input-suffix ml-2" name="spirometerFVC" formControlName="spirometerFVC" (ngModelChange)="calculateRatio()"/>
   <span class="custom-suffix">L/min</span>
  </div>
  <div *ngIf="dailyAsthmaForm.get('spirometerFVC').invalid && (dailyAsthmaForm.get('spirometerFVC').dirty || dailyAsthmaForm.get('spirometerFVC').touched)" class="text-danger">
    <span *ngIf="dailyAsthmaForm.get('spirometerFVC').hasError('min')">
      Minimum value allowed is 0.
    </span>
    </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Spirometer test - FEV1/FVC</label>
    </div>
   <input type="number" class="custom-input" name="spirometerRatio" formControlName="spirometerRatio" readonly/>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Peak flow test (PEF): take the test 3 times and report the highest number of those 3</label>
    </div>
    <div class="row">
      <input type="number" class="custom-input custom-input-suffix ml-2"
      formControlName="peakFlowTest" name="peakFlowTest"/>
   <span class="custom-suffix">L/min</span>
  </div>
  <div *ngIf="dailyAsthmaForm.get('peakFlowTest').invalid && (dailyAsthmaForm.get('peakFlowTest').dirty || dailyAsthmaForm.get('peakFlowTest').touched)" class="text-danger">
    <span *ngIf="dailyAsthmaForm.get('peakFlowTest').hasError('required')">
      Peak flow test cannot be empty.
    </span>
    <span *ngIf="dailyAsthmaForm.get('peakFlowTest').hasError('min')">
      Minimum value allowed is 0.
    </span>
    <span *ngIf="dailyAsthmaForm.get('peakFlowTest').hasError('max')">
      Maximum value allowed is up to 9000.
    </span>
  </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">PulseOx test (O2 test)</label>
    </div>
    <div class="row">
      <input type="number"
             class="custom-input custom-input-suffix ml-2"
             name="pulseOxTest"
             formControlName="pulseOxTest"
             />
      <span class="custom-suffix">%</span>
    </div>
    <div *ngIf="dailyAsthmaForm.get('pulseOxTest').invalid && (dailyAsthmaForm.get('pulseOxTest').dirty || dailyAsthmaForm.get('pulseOxTest').touched)" class="text-danger">
      <span *ngIf="dailyAsthmaForm.get('pulseOxTest').hasError('required')">
        pulseOxTest cannot be empty.
      </span>
      <span *ngIf="dailyAsthmaForm.get('pulseOxTest').hasError('min')">
        Minimum value allowed is 0.
      </span>
      <span *ngIf="dailyAsthmaForm.get('pulseOxTest').hasError('max')">
        Maximum value allowed is up to 100.
      </span>
    </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Comments</label>
    </div>
   <input type="text" class="custom-input" name="comments" formControlName="comments"/>
  </div>
</div>

<div class="wrap-box">
  <button class="btn-save">Submit</button>
</div>
</form>
