<!-- <app-loader *ngIf="loading"></app-loader> -->
<div class="row" *ngIf="!loading">
  <section class="col-lg-6 ">
    <div class="card lightblue">
      <div class="card-header noborderbottom">
        <div class="card-title">
          <i class="material-icons">group</i>
          <span> Patients overview</span>
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <ul class="todo-list" data-widget="todo-list">
          <li [routerLink]="['/patients']">

            <!-- todo text -->
            <span class="text">Total enrolled patients</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">

              <small class="badge badge-light">{{summary.noOfEnrolledPatients}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfRPMEnrolledPatients'}">
            <!-- todo text -->
            <span class="text">Total patients in RPM</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.noOfRPMEnrolledPatients}}</small>
            </div>
          </li>

          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfCCMEnrolledPatients'}">
            <!-- todo text -->
            <span class="text">Total patients in CCM</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.noOfCCMEnrolledPatients}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfPCMEnrolledPatients'}">
            <!-- todo text -->
            <span class="text">Total patients in PCM</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.noOfPCMEnrolledPatients}}</small>
            </div>
          </li>


        </ul>
      </div>

      <div class="card-body">

        <ul class="todo-list" data-widget="todo-list">


          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'bookmarkedPatients'}">
            <!-- todo text -->
            <span class="text">Bookmarked patients</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.bookmarkedPatients}}</small>
            </div>
          </li>

          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'frequentIHRCount'}">
            <span class="text">Frequent IHR patients </span>
            <div class="tools">
              <small class="badge badge-light">{{summary.frequentIHRCount}}</small>
            </div>
          </li>

          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfPatientsWithNewMeasuerements'}">
            <!-- todo text -->
            <span class="text">Patients with new measurements</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.noOfPatientsWithNewMeasuerements}}</small>
            </div>
          </li>

          <li [routerLink]="['/forms/cardiac-screening/completed']" [queryParams]="{flagCardiac: true}">
            <!-- todo text -->
            <span class="text">Marked for cardiac screening</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{markedCardiacData.markedCardiacScreeningCount}}</small>
            </div>
          </li>

        </ul>
      </div>
      <div class="card-body">

        <ul class="todo-list" data-widget="todo-list">
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfEnrolledPatientsInPast30Days'}">
            <!-- todo text -->
            <span class="text">New patients &lt; 30 days</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.noOfEnrolledPatientsInPast30Days}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfEnrolledPatientsInPast60Days'}">
            <!-- todo text -->
            <span class="text">New patients &lt; 60 days</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.noOfEnrolledPatientsInPast60Days}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfEnrolledPatientsInPast90Days'}">
            <!-- todo text -->
            <span class="text">New patients &lt; 90 days </span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.noOfEnrolledPatientsInPast90Days}}</small>
            </div>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <ul class="todo-list" data-widget="todo-list">
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'totalBPDeviceCount'}">
            <!-- todo text -->
            <span class="text">Blood pressure</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.totalBPDeviceCount}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'totalGlucometerDeviceCount'}">
            <!-- todo text -->
            <span class="text">Glucose</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.totalGlucometerDeviceCount}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'totalScaleDeviceCount'}">
            <!-- todo text -->
            <span class="text">Weight</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.totalScaleDeviceCount}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'totalPulseoxDeviceCount'}">
            <!-- todo text -->
            <span class="text">Blood oxygen</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.totalPulseoxDeviceCount}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'totalThermometerDeviceCount'}">
            <!-- todo text -->
            <span class="text">Temperature</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.totalThermometerDeviceCount}}</small>
            </div>
          </li>
          <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'totalIsCGMEnrolledCount'}">
            <!-- todo text -->
            <span class="text">CGM</span>
            <!-- General tools such as edit or delete-->
            <div class="tools">
              <small class="badge badge-light">{{summary.totalIsCGMEnrolledCount}}</small>
            </div>
          </li>
          <!-- <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'totalScaleDeviceCount'}">
            <span class="text">Scale</span>
            <div class="tools">
              <small class="badge badge-light">{{summary.totalScaleDeviceCount}}</small>
            </div>
          </li> -->
        </ul>
      </div>


    </div>
  </section>
  <section class="col-lg-6 connectedSortable">
    <div>

      <div class="card lightyellow">
        <div class="card-header noborderbottom">
          <div class="card-title">
            <i class="material-icons">assignment</i>
            <span> To do list overview</span>
          </div>
        </div>

        <div class="card-body">
          <ul class="todo-list" data-widget="todo-list">

            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfPatientsWithAlerts'}">

              <span class="text">Total patients with notifications</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.noOfPatientsWithAlerts}}</small>
              </div>
            </li>

            <li [routerLink]="['/patients/alltasks']" [queryParams]="{searchTerm: 'noofPendingTask'}">

              <span class="text">Pending tasks</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.noofPendingTask}}</small>
              </div>
            </li>

            <li [routerLink]="['/patients/allmessages']" [queryParams]="{searchTerm: 'noOfUnreadMessages'}">

              <span class="text">Unread messages</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.noOfUnreadMessages}}</small>
              </div>
            </li>

            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'followUpWithRPMNonCompliantPatients'}">

              <span class="text">Follow up with RPM non-compliant patients</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.followUpWithRPMNonCompliantPatients}}</small>
              </div>
            </li>

            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfRPMPatientsRequireCommunication'}">

              <span class="text">Patients requiring a monthly call</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.noOfRPMPatientsRequireCommunication}}</small>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="card  mt-3 lightsalmon">
        <div class="card-header noborderbottom">
          <div class="card-title">
            <i class="material-icons">monetization_on</i>
            <span> Billing overview (updated hourly) </span>
          </div>

        </div>
        <!-- /.card-header -->
        <div class="card-body">
          <ul class="todo-list" data-widget="todo-list">

            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'billingMinutesUnder5'}">

              <span class="text">Patients with 0-5 mins</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.billingMinutesUnder5}}</small>
              </div>
            </li>


            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'billingMinutes6to10'}">

              <span class="text">Patients with 6-10 mins</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.billingMinutes6to10}}</small>
              </div>
            </li>

            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'billingMinutes11to15'}">

              <span class="text">Patients with 11-15 mins</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.billingMinutes11to15}}</small>
              </div>
            </li>

            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'billingMinutes16to19'}">

              <span class="text">Patients with 16-19 mins</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.billingMinutes16to19}}</small>
              </div>
            </li>

            <li [routerLink]="['/patients']" [queryParams]="{searchTerm: 'billingMinutes20Above'}">

              <span class="text">Patients with 20+ mins</span>

              <div class="tools">
                <small class="badge badge-light">{{summary.billingMinutes20Above}}</small>
              </div>
            </li>


            <li>
              <!-- todo text -->
              <span class="text">Total minutes this month</span>
              <!-- General tools such as edit or delete-->
              <div class="tools">
                <small class="badge badge-light">{{summary.totalMinutesThisMonth}}</small>
              </div>
            </li>
            <li>
              <!-- todo text -->
              <span class="text">Total minutes previous month</span>
              <!-- General tools such as edit or delete-->
              <div class="tools">
                <small class="badge badge-light">{{summary.totalMinutesPreviousMonth}}</small>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>

  </section>

</div>
