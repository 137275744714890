import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { first, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  mfaForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  mfaSubmitted =false;
  isSmsMfa = false;
  returnUrl: string;
  error = '';

  userName ="";
  session="";
  msg: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.mfaForm = this.formBuilder.group({
      smsMfaCode: ['', Validators.required]

    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }


  get f() { return this.loginForm.controls; }
  get mfa() { return this.mfaForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value.trim(), this.f.password.value)
      .pipe(
        tap(() => {
          this.msg = "";
          this.isSmsMfa = false;

        }),
        finalize(() => {
          this.loading = false;
        }))
      .subscribe(
        data => {

          if (!data) {
            this.msg = "Login failed.";
          }
          else if (data.StatusCode && data.StatusCode == 500) {
            this.msg = data.Message;
          }
          else if (data.challengeName && data.challengeName.value == "SMS_MFA") {
            this.isSmsMfa = true;
            this.session = data.session
            this.userName = this.f.username.value.trim();
          }
          else {
            this.router.navigate([this.returnUrl]);
          }

        },
        error => {
          this.msg = "Either email or password does not match";
          this.loading = false;
        });
  }

  onSubmitMfa() {
    this.mfaSubmitted = true;

    if (this.mfaForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.mfa(this.userName.trim(), this.mfa.smsMfaCode.value, this.session)
      .pipe(
        tap(() => {
          this.msg = "";
          this.isSmsMfa = false;

        }),
        finalize(() => {
          this.loading = false;
        }))
      .subscribe(
        data => {

          if (!data) {
            this.msg = "Login failed.";
          }
          else if (data.StatusCode && data.StatusCode == 500) {
            this.msg = data.Message;
          }
          else if (data.challengeName && data.challengeName == "SMS_MFA") {
            this.isSmsMfa = true;
          }
          else {
            this.router.navigate([this.returnUrl]);
          }

        },
        error => {

          this.msg = "Either email or password does not match";
          this.loading = false;
        });
  }
  reset()
  {
    this.msg = "";
    this.isSmsMfa = false;
    this.loading = false;
    this.userName ="";
    this.session="";
  }
}
