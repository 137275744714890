import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import posthog from 'posthog-js';

Sentry.init({
  dsn: 'https://7b14d884db29e3de09b8b72e44e9f753@o4506345010364416.ingest.sentry.io/4506388792344576',
  enabled: environment.isSentryEnabled,
  environment: environment.name,
  integrations: [Sentry.browserTracingIntegration()],

  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', environment.patientUrl],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // initialize posthog
  if(environment.name !== "local"){
    posthog.init('phc_5Z33je4vf0GxsztFMGQuWnRknq4SXmEbYmrOFBlJhlw', { api_host: 'https://app.posthog.com' })

  var clinics = JSON.parse(localStorage.getItem('CLINICS'));
  var ClinicId = clinics[0].Id;
  var clinicId = ClinicId.toString();

    posthog.capture(
      '$autocapture',
      {
          $set: { 'clinic-id': clinicId  }
      }
  )
  }
