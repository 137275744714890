import { Component, Input, OnInit } from '@angular/core';
import { Patient, PreopFormData, PreopFormHeading } from '../pre-op-assessment.model';
import { ActivatedRoute, Router } from '@angular/router';;
import { PreopAssessmentService } from '../pre-op.service';
import moment from 'moment';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pre-op-assessment-form',
  templateUrl: './pre-op-assessment-form.component.html',
  styleUrls: ['./pre-op-assessment-form.component.scss']
})
export class PreOpAssessmentFormComponent implements OnInit {
  @Input() isClinicView: boolean = true;
  id: string = '';
  Isreview: boolean = false;
  ISInvalid: boolean = false;
  todayDate = moment(new Date()).format('MMMM DD yyyy');
  dateofbirth: string = moment(new Date()).format('YYYY-MM-DD');
  covidDiagnosisDates: any = new UntypedFormControl();
  smokingComment: any = new UntypedFormControl();
  pragnantDueDate: any = new UntypedFormControl();

  constructor(
    private PreopService: PreopAssessmentService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
  ) { }

 formHeading: PreopFormHeading = {
    clinicName: '',
    form: '',
    formName:'',
    scope: '',
    createdAt: ''
  }

PreOpFormData: PreopFormData = {
  age: 0,
  weight: 0,
  height: 0,
  isAllergies: null,
  allergiesComment: '',
  surgicalHistory: '',
  currMedications: '',
  respiratoryInfection: null,
  covidDiagnosisDate: '',
  isLatexAllergic: null,
  isCheastPain: null,
  isHeartCondition: null,
  isHypetension: null,
  isShortnessBreath: null,
  isanyBreathingCondition: null,
  isSmoking: null,
  smokingComment: '',
  isAlcoholic: null,
  AlcoholiComment: '',
  isRecreationalDrugs: null,
  isSteroidal: null,
  isNonsteroidal: null,
  isTakeAspirin: null,
  isAltMedicines: null,
  altMedicinesComment: '',
  isDiabetes: null,
  isDiabetesMadications: null,
  isThyroidCondition: null,
  iskidneyCondition: null,
  isStomachDisorders: null,
  isHiatalHernia: null,
  isNeakPain: null,
  isNerveDisease: null,
  isSickleCellDisease: null,
  isAnaesthesia: null,
  isBleeding: null,
  isOralCondition: null,
  isContactLenses: null,
  isBloodTransfusion: null,
  isPragnant: null,
  pragnantDueDate: '',
  isErectileDysfunction: null,
  id: '',
  dataKey: '',
  resource: '',
  patient: {
    dateOfBirth: '',
    firstName: '',
    lastName: ''
  },
  createdAt: '',
  status: ''
}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    this.GetPreopHeadingData();
    this.GetAsthmaPatientdetails();
  }

  allergiesInput(value: boolean) {
    if (value !== true) {
      this.PreOpFormData.allergiesComment = '';
    }
  }

  respiratoryInput(value: boolean) {
    if (value !== true) {
      this.PreOpFormData.covidDiagnosisDate = '';
    }
  }

  smokingInput(value: boolean) {
    if (value !== true) {
      this.PreOpFormData.smokingComment = '';
    }
  }

  alcoholicInput(value: boolean) {
    if (value !== true) {
      this.PreOpFormData.AlcoholiComment = '';
    }
  }

  altMedicinesInput(value: boolean) {
    if (value !== true) {
      this.PreOpFormData.altMedicinesComment = '';
    }
  }

  femalesInput(value: boolean) {
    if (value !== true) {
      this.PreOpFormData.pragnantDueDate = '';
    }
  }

  GetPreopHeadingData() {
    this.PreopService.GetPreopHeading(this.id).subscribe(
      (results: PreopFormHeading) => {
        this.formHeading = results;
      },
      (error) => {
       }
    );
  }

  GetAsthmaPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.PreopService.GetPreopPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.PreOpFormData = results;
        this.PreOpFormData.patient = results.patient;
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['questionnaires/pre-op-assessment/' + this.id]);
      }
    );
  }

  savePreop() {
    this.PreOpFormData.status = 'COMPLEATED';
    this.PreopService.SavePreopPatient(
      this.id,
      this.PreOpFormData
    ).subscribe(
      (results: any) => {
        this.router.navigate(['questionnaires/pre-op-assessment/'+ this.id +'/thank-you']);
      },
      (error) => {
      }
    );
  }
  onDateInput(event: MatDatepickerInputEvent<Date>) {
      const selectedDate = event.value;
      this.PreOpFormData.covidDiagnosisDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
      }
  onsmokingCommentDateInput(event: MatDatepickerInputEvent<Date>) {
      const selectedDate = event.value;
      this.PreOpFormData.smokingComment = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }
  onDueDateDateInput(event: MatDatepickerInputEvent<Date>) {
      const selectedDate = event.value;
      this.PreOpFormData.smokingComment = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }
}

