import { Component, OnInit } from '@angular/core';
import { CardiacScreeningComponent } from '../cardiac-screening.component';
import { CardiacFormHeading } from '../cardiac-screening.model';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { CardiacScreeningService } from '../cardiac-screening.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
@Component({
  selector: 'app-cardiac-screening-verify-dob',
  templateUrl: './cardiac-screening-verify-dob.component.html',
  styleUrls: ['./cardiac-screening-verify-dob.component.scss']
})
export class CardiacScreeningVerifyDobComponent implements OnInit {

  constructor(
    private CardiacScreeningComponent: CardiacScreeningComponent,
    private route: ActivatedRoute,
    private CardiacScreeningService: CardiacScreeningService,
    private auth: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe) { }

  formHeading: CardiacFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  Isreview: boolean = false;
  ISInvalid: boolean = false;
  todayDate = moment(new Date()).format('MMMM DD yyyy');
  dateofbirths: any = new UntypedFormControl(new Date());
  dateofbirth: string =  moment(new Date()).format('YYYY-MM-DD');
  id: string = '';

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    this.GetHeading();
  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.dateofbirth = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }

  next() {
    this.CardiacScreeningService.GetCardiacform(this.id, this.dateofbirth).subscribe(
      (results) => {
        if (results != null || results.id != '') {
          this.auth.setItem('dateOfBirth', this.dateofbirth);
          this.CardiacScreeningComponent.onNextComponentClick();
        } else {
          this.ISInvalid = true;
        }
      },
      (error) => {
        if (error.status == 403) {
          this.router.navigate(['questionnaires/cardiac-screening/' + this.id + '/thank-you']);
          // this.Isreview = true;
        } else {
          this.ISInvalid = true;
        }
      }
    );
  }

  GetHeading() {
    this.CardiacScreeningService.GetHeadingData(this.id).subscribe(
      (results: CardiacFormHeading) => {
        this.formHeading = results;
      },
      (error) => {
      }
    );
  }
}
