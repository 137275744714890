import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ComponentsModule } from './components/components.module';
import { Router, RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// @ts-ignore
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Interceptor } from './authentication/interceptor';
// import { ToastrModule } from 'ngx-toastr';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { CanDeactivateGuard } from './patients/can-deactivate.guard';
import { MatInputModule } from '@angular/material/input';
import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
import { AppComponent } from './app.component';
import * as Sentry from "@sentry/angular";
import { ToastrModule } from 'ngx-toastr';
import { AnnualWellnessVisitFormModule } from './annual-wellness-visit-form/annual-wellness-visit-form.module';
import { CcmSelfReportingVitalsModule } from './ccm-self-reporting-vitals/ccm-self-reporting-vitals.module';
import { MonthlyAsthmaControlTestModule } from './monthly-asthma-control-test/monthly-asthma-control-test.module';
import { DailyAsthmaReportingModule } from './asthma-self-reporting-daily/asthma-self-reporting-daily.module';
import { FormSubmittedScreenComponent } from './form-submitted-screen/form-submitted-screen.component';
import { cardiacscreeningmodule } from './cardiac-screening/cardiac-screening.module';
import { PreOpAssessmentmodule } from './pre-op-assessment/pre-op-assessment.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

import { RtmPainModule } from './rtm-pain/rtm-pain.module';

import { authenticationModule } from './authentication/authentication.module';
import { InvitePatientsModule } from './invite-patients/invite-patients.module';
import { dashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  callback(zE: any) {
    // You can call every command you want in here
    zE('webWidget', 'hide');
  }
  accountUrl = 'larahealth.zendesk.com';
  beforePageLoad(zE) {
    zE.setLocale('en');

  }
}


@NgModule({ declarations: [
        AppComponent,
        FormSubmittedScreenComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        RouterModule,
        MatInputModule,
        authenticationModule,
        InvitePatientsModule,
        dashboardModule,
        MatDatepickerModule,
        AnnualWellnessVisitFormModule,
        CcmSelfReportingVitalsModule,
        MonthlyAsthmaControlTestModule,
        DailyAsthmaReportingModule,
        cardiacscreeningmodule,
        PreOpAssessmentmodule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ComponentsModule,
        FormsModule,
        RtmPainModule,
        SharedModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)], providers: [
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
        CanDeactivateGuard,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
