
<form #form="ngForm" (ngSubmit)="savePreop()">
  <div class="">
    <div><span class="text-20-bold-700 line-height-48">{{formHeading.formName}}</span></div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Medical practice: </span>
      <span class="text-20-bold-400">{{formHeading.clinicName}}</span>
    </div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Document created: </span>
      <span class="text-20-bold-400" *ngIf="formHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
      <span class="text-20-bold-400" *ngIf="formHeading.createdAt !== null">{{formHeading.createdAt | date: 'MMMM dd yyyy'}}</span>
    </div>
    <div *ngIf="isClinicView">
      <span class="text-20-bold-700">Note: </span>
      <span class="text-20-bold-400">Please fill out the form to your best abilities.
        Skip the questions you don’t know the answers to. Your care team will be able to complete the form after you submit it.</span>
  </div>
  </div>

  <div class="text-20-bold-700 line-height-48">Patient details:</div>

  <div class="wrap-box">
    <div>
      <label class="label-font-14">First name</label>
    </div>
    <input class="custom-input " type="text" placeholder="Enter first name" name="firstName" [(ngModel)]="PreOpFormData.patient.firstName" disabled="true"/>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Last name</label>
    </div>
    <input class="custom-input " type="text" placeholder="Enter last name" name="lastName" [(ngModel)]="PreOpFormData.patient.lastName" disabled="true"/>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Date of birth</label>
    </div>
    <input class="custom-input" type="date" placeholder="Enter date of birth" name="dateOfBirth" [(ngModel)]="PreOpFormData.patient.dateOfBirth" disabled="true"/>
  </div>

  <div class="wrap-box" >
    <label class="normal-fonts">Please answer the following questions:</label>
  </div>

  <div class="main-div">
    <div class="wrap-box">
      <div>
        <label class="label-font-14">Your age</label>
      </div>
      <input class="custom-input" type="number" max="110" name="age" maxlength="3" [(ngModel)]="PreOpFormData.age" />
      <!-- <div *ngIf="form.submitted && form.invalid"> -->
      <div *ngIf="form.submitted && ( PreOpFormData.age > 110 )">
        <span class="text-danger">Maximum age limit is 110</span>
      </div>
    <!-- </div> -->
    </div>
    <div class="wrap-box">
      <div>
        <label class="label-font-14">Your weight</label>
      </div>
      <input class="custom-input" type="text" name="weight" [(ngModel)]="PreOpFormData.weight"/>
    </div>
    <div class="wrap-box">
      <div>
        <label class="label-font-14">Your height</label>
      </div>
      <input class="custom-input" type="text" name="height" [(ngModel)]="PreOpFormData.height"/>
    </div>

  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="label-font-14">Any known allergies?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="allergies1" name="isAllergies" [(ngModel)]="PreOpFormData.isAllergies" [value]="true" (ngModelChange)="allergiesInput(PreOpFormData.isAllergies)">
      <label for="allergies1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="allergies2" name="isAllergies" [(ngModel)]="PreOpFormData.isAllergies" [value]="false" (ngModelChange)="allergiesInput(PreOpFormData.isAllergies)">
      <label for="allergies2">No</label>
    </div>
  </div>
  <div class="wrap-box">
      <label class="label-font-14">If yes please list them here:</label>
    <input class="custom-input" type="text" name="allergiesComment" [(ngModel)]="PreOpFormData.allergiesComment" [disabled]="PreOpFormData.isAllergies !== true"/>
  </div>

  <div class="wrap-box">
    <div>
      <label class="label-font-14">Your surgical history</label>
    </div>
    <input class="custom-input" type="text" name="surgicalHistory" [(ngModel)]="PreOpFormData.surgicalHistory"/>
  </div>

  <div class="wrap-box">
    <div>
      <label class="label-font-14">Current medications</label>
    </div>
    <input class="custom-input" type="text" name="currMedications" [(ngModel)]="PreOpFormData.currMedications" />
  </div>

  <div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="label-font-14">Have you had a respiratory infection, such as cold, COVID-19, flu, or RSV?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="respiratory1" name="respiratoryInfection" [(ngModel)]="PreOpFormData.respiratoryInfection" [value]="true" (ngModelChange)="respiratoryInput(PreOpFormData.respiratoryInfection)">
      <label for="respiratory1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="respiratory2" name="respiratoryInfection" [(ngModel)]="PreOpFormData.respiratoryInfection" [value]="false" (ngModelChange)="respiratoryInput(PreOpFormData.respiratoryInfection)">
      <label for="respiratory2">No</label>
    </div>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">If it was confirmed as a COVID-19 infection, then what was the diagnosis date?</label>
    <!--  <input class="custom-input" type="date" name="covidDiagnosisDate"  [(ngModel)]="PreOpFormData.covidDiagnosisDate" [disabled]="PreOpFormData.respiratoryInfection !== true"/> -->
    <mat-form-field floatLabel="never" class="mat-datepicker" (click)="picker.open()">
      <input matInput [matDatepicker]="picker" autocomplete="off" [value]="covidDiagnosisDates.value"  (dateChange)="onDateInput($event)" [disabled]="PreOpFormData.respiratoryInfection !== true" placeholder="MM/DD/YYYY">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
</div>

  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Are you allergic to latex (rubber) products?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="latexProducts1" name="isLatexAllergic"  [(ngModel)]="PreOpFormData.isLatexAllergic"  [value]="true">
      <label for="latexProducts1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="latexProducts2" name="isLatexAllergic"  [(ngModel)]="PreOpFormData.isLatexAllergic"  [value]="false">
      <label for="latexProducts2" >No</label>
    </div>
  </div>

  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Have you experienced chest pain?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="chestPain1" name="isCheastPain" [(ngModel)]="PreOpFormData.isCheastPain"  [value]="true">
      <label for="chestPain1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="chestPain2" name="isCheastPain" [(ngModel)]="PreOpFormData.isCheastPain"  [value]="false">
      <label for="chestPain2" >No</label>
    </div>
  </div>

  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Do you have a heart condition?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="heartCondition1" name="isHeartCondition" [(ngModel)]="PreOpFormData.isHeartCondition" [value]="true">
      <label for="heartCondition1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="heartCondition2" name="isHeartCondition" [(ngModel)]="PreOpFormData.isHeartCondition" [value]="false">
      <label for="heartCondition2" >No</label>
    </div>
  </div>

  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Do you have hypertension (high blood pressure)?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="hypertension1" name="isHypetension" [(ngModel)]="PreOpFormData.isHypetension"  [value]="true">
      <label for="hypertension1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="hypertension2" name="isHypetension" [(ngModel)]="PreOpFormData.isHypetension" [value]="false">
      <label for="hypertension2" >No</label>
    </div>
  </div>

  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Do you experience shortness of breath?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="breathShortness1" name="isShortnessBreath" [(ngModel)]="PreOpFormData.isShortnessBreath"  [value]="true">
      <label for="breathShortness1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="breathShortness2" name="isShortnessBreath" [(ngModel)]="PreOpFormData.isShortnessBreath" [value]="false">
      <label for="breathShortness2" >No</label>
    </div>
  </div>

  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Do you have asthma, bronchitis, and/or any other breathing condition?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="bronchitis1" name="isanyBreathingCondition" [(ngModel)]="PreOpFormData.isanyBreathingCondition" [value]="true">
      <label for="bronchitis1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="bronchitis2" name="isanyBreathingCondition" [(ngModel)]="PreOpFormData.isanyBreathingCondition" [value]="false">
      <label for="bronchitis2" >No</label>
    </div>
  </div>

  <div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label> Do you smoke, or have you smoked, cigarettes?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="cigarettes1" name="isSmoking" [(ngModel)]="PreOpFormData.isSmoking" [value]="true" (ngModelChange)="smokingInput(PreOpFormData.isSmoking)">
      <label for="cigarettes1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="cigarettes2" name="isSmoking" [(ngModel)]="PreOpFormData.isSmoking" [value]="false" (ngModelChange)="smokingInput(PreOpFormData.isSmoking)">
      <label for="cigarettes2">No</label>
    </div>
  </div>
  <div class="wrap-box">
    <div>
    <label class="label-font-14">If yes: packs/day, number of years smoked, and date you stopped smoking (if you stopped).</label>
    </div>
    <!-- <input class="custom-input" type="text" name="smokingComment" [(ngModel)]="PreOpFormData.smokingComment" [disabled]="PreOpFormData.isSmoking !== true"/> -->
    <mat-form-field floatLabel="never" class="mat-datepicker" (click)="smoking.open()">
      <input matInput [matDatepicker]="smoking" autocomplete="off" [value]="smokingComment.value"  (dateChange)="onsmokingCommentDateInput($event)" [disabled]="PreOpFormData.isSmoking !== true" placeholder="MM/DD/YYYY">
      <mat-datepicker-toggle matSuffix [for]="smoking"></mat-datepicker-toggle>
      <mat-datepicker #smoking></mat-datepicker>
  </mat-form-field>
</div>
</div>

<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Do you consume alcohol?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="consumeAlcohol1" name="isAlcoholic" [(ngModel)]="PreOpFormData.isAlcoholic" [value]="true" (ngModelChange)="alcoholicInput(PreOpFormData.isAlcoholic)">
      <label for="consumeAlcohol1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="consumeAlcohol2" name="isAlcoholic" [(ngModel)]="PreOpFormData.isAlcoholic"  [value]="false" (ngModelChange)="alcoholicInput(PreOpFormData.isAlcoholic)">
      <label for="consumeAlcohol2" >No</label>
    </div>
  </div>
  <div class="wrap-box">
    <div>
    <label class="label-font-14">If yes, how many drinks/week?</label>
    </div>
    <input class="custom-input" type="text" name="AlcoholiComment" [(ngModel)]="PreOpFormData.AlcoholiComment" [disabled]="PreOpFormData.isAlcoholic !== true"/>
</div>
</div>

<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Do you use, or have you ever used, recreational drugs?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="recreational1" name="isRecreationalDrugs" [(ngModel)]="PreOpFormData.isRecreationalDrugs" [value]="true">
      <label for="recreational1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="recreational2" name="isRecreationalDrugs" [(ngModel)]="PreOpFormData.isRecreationalDrugs" [value]="false">
      <label for="recreational2" >No</label>
    </div>
  </div>
</div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Have you taken cortisone (steroids) in the past 6 months?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="cortisone1" name="isSteroidal" [(ngModel)]="PreOpFormData.isSteroidal" [value]="true">
        <label for="cortisone1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="cortisone2" name="isSteroidal" [(ngModel)]="PreOpFormData.isSteroidal" [value]="false">
        <label for="cortisone2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you take any nonsteroidal anti-inflammatory drugs (NSAID's), such as ibuprofen
          (Advil) and/or naproxen (Aleve)?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="nonsteroidal1" name="isNonsteroidal" [(ngModel)]="PreOpFormData.isNonsteroidal" [value]="true">
        <label for="nonsteroidal1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="nonsteroidal2" name="isNonsteroidal" [(ngModel)]="PreOpFormData.isNonsteroidal" [value]="false">
        <label for="nonsteroidal2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you take aspirin?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="aspirin1" name="isTakeAspirin" [(ngModel)]="PreOpFormData.isTakeAspirin" [value]="true">
        <label for="aspirin1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="aspirin2" name="isTakeAspirin" [(ngModel)]="PreOpFormData.isTakeAspirin" [value]="false">
        <label for="aspirin2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you take any herbal supplements, complementary or alternative medicines, or
          vitamins? </label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="vitamins1" name="isAltMedicines" [(ngModel)]="PreOpFormData.isAltMedicines" [value]="true" (ngModelChange)="altMedicinesInput(PreOpFormData.isAltMedicines)">
        <label for="vitamins1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="vitamins2" name="isAltMedicines" [(ngModel)]="PreOpFormData.isAltMedicines" [value]="false" (ngModelChange)="altMedicinesInput(PreOpFormData.isAltMedicines)">
        <label for="vitamins2" >No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div>
      <label class="label-font-14">If yes so, then which ones do you take, and how recently have you taken them?</label>
      </div>
      <input class="custom-input" type="text" name="altMedicinesComment" [(ngModel)]="PreOpFormData.altMedicinesComment" [disabled]="PreOpFormData.isAltMedicines !== true"/>
  </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have diabetes?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="diabetes1" name="isDiabetes" [(ngModel)]="PreOpFormData.isDiabetes" [value]="true">
        <label for="diabetes1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="diabetes2" name="isDiabetes" [(ngModel)]="PreOpFormData.isDiabetes" [value]="false">
        <label for="diabetes2" >No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
      <label>If you do, then do you take any medications for diabetes?</label>
      </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="takeMedications1" name="isDiabetesMadications" [(ngModel)]="PreOpFormData.isDiabetesMadications" [value]="true">
      <label for="takeMedications1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="takeMedications2" name="isDiabetesMadications" [(ngModel)]="PreOpFormData.isDiabetesMadications" [value]="false">
      <label for="takeMedications2" >No</label>
    </div>
  </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have a thyroid condition?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="thyroid1" name="isThyroidCondition" [(ngModel)]="PreOpFormData.isThyroidCondition" [value]="true">
        <label for="thyroid1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="thyroid2" name="isThyroidCondition" [(ngModel)]="PreOpFormData.isThyroidCondition" [value]="false">
        <label for="thyroid2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have, or have you ever had, a kidney condition?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="kidney1" name="iskidneyCondition" [(ngModel)]="PreOpFormData.iskidneyCondition" [value]="true">
        <label for="kidney1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="kidney2" name="iskidneyCondition" [(ngModel)]="PreOpFormData.iskidneyCondition" [value]="false">
        <label for="kidney2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label> Do you have ulcers, gastroesophageal reflux (GERD, heartburn) and/or other stomach
          disorders?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ulcers1" name="isStomachDisorders" [(ngModel)]="PreOpFormData.isStomachDisorders" [value]="true">
        <label for="ulcers1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ulcers2" name="isStomachDisorders" [(ngModel)]="PreOpFormData.isStomachDisorders" [value]="false">
        <label for="ulcers2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have a hiatal hernia?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="hiatal1" name="isHiatalHernia" [(ngModel)]="PreOpFormData.isHiatalHernia" [value]="true">
        <label for="hiatal1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="hiatal2" name="isHiatalHernia" [(ngModel)]="PreOpFormData.isHiatalHernia" [value]="false">
        <label for="hiatal2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have back and/or neck pain?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="neckPain1" name="isNeakPain" [(ngModel)]="PreOpFormData.isNeakPain" [value]="true">
        <label for="neckPain1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="neckPain2" name="isNeakPain" [(ngModel)]="PreOpFormData.isNeakPain" [value]="false">
        <label for="neckPain2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have any muscle and/or nerve disease? </label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="nerveDisease1" name="isNerveDisease" [(ngModel)]="PreOpFormData.isNerveDisease" [value]="true">
        <label for="nerveDisease1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="nerveDisease2" name="isNerveDisease" [(ngModel)]="PreOpFormData.isNerveDisease" [value]="false">
        <label for="nerveDisease2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you and/or any of your family members have sickle cell disease or trait?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="sickleCell1" name="isSickleCellDisease" [(ngModel)]="PreOpFormData.isSickleCellDisease" [value]="true">
        <label for="sickleCell1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="sickleCell2" name="isSickleCellDisease" [(ngModel)]="PreOpFormData.isSickleCellDisease" [value]="false">
        <label for="sickleCell2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Have you and/or any of your family members had any complications with anaesthesia?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="anaesthesia1" name="isAnaesthesia" [(ngModel)]="PreOpFormData.isAnaesthesia" [value]="true">
        <label for="anaesthesia1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="anaesthesia2" name="isAnaesthesia" [(ngModel)]="PreOpFormData.isAnaesthesia" [value]="false">
        <label for="anaesthesia2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have bleeding problems?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="bleeding1" name="isBleeding" [(ngModel)]="PreOpFormData.isBleeding" [value]="true">
        <label for="bleeding1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="bleeding2" name="isBleeding"  [(ngModel)]="PreOpFormData.isBleeding"  [value]="false">
        <label for="bleeding2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you have any loose, chipped, or false teeth? Bridgework? Oral piercings?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Bridgework1" name="isOralCondition" [(ngModel)]="PreOpFormData.isOralCondition" [value]="true">
        <label for="Bridgework1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Bridgework2" name="isOralCondition" [(ngModel)]="PreOpFormData.isOralCondition" [value]="false">
        <label for="Bridgework2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Do you wear contact lenses?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="contactLenses1" name="isContactLenses" [(ngModel)]="PreOpFormData.isContactLenses" [value]="true">
        <label for="contactLenses1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="contactLenses2" name="isContactLenses" [(ngModel)]="PreOpFormData.isContactLenses" [value]="false">
        <label for="contactLenses2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Have you ever received a blood transfusion?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="BloodTransfusion1" name="isBloodTransfusion" [(ngModel)]="PreOpFormData.isBloodTransfusion" [value]="true">
        <label for="BloodTransfusion1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="BloodTransfusion2" name="isBloodTransfusion" [(ngModel)]="PreOpFormData.isBloodTransfusion" [value]="false">
        <label for="BloodTransfusion2" >No</label>
      </div>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label> Females: are you pregnant?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="females1" name="isPragnant" [(ngModel)]="PreOpFormData.isPragnant" [value]="true" (ngModelChange)="femalesInput(PreOpFormData.isPragnant)">
        <label for="females1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="females2" name="isPragnant" [(ngModel)]="PreOpFormData.isPragnant" [value]="false" (ngModelChange)="femalesInput(PreOpFormData.isPragnant)">
        <label for="females2" >No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">If yes, what is the due date?</label>
      <!-- <input class="custom-input" type="date" name="pragnantDueDate" [(ngModel)]="PreOpFormData.pragnantDueDate" [disabled]="PreOpFormData.isPragnant !== true"/> -->
      <mat-form-field floatLabel="never" class="mat-datepicker" (click)="DueDate.open()">
        <input matInput [matDatepicker]="DueDate" autocomplete="off" [value]="pragnantDueDate.value"  (dateChange)="onDueDateDateInput($event)" [disabled]="PreOpFormData.isPragnant !== true" placeholder="MM/DD/YYYY">
        <mat-datepicker-toggle matSuffix [for]="DueDate"></mat-datepicker-toggle>
        <mat-datepicker #DueDate></mat-datepicker>
    </mat-form-field>
    </div>
  </div>

  <div class="one-question">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label>Males: do you or have you ever taken medications for erectile dysfunction, such as Cialis
          or Viagra?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="cialis1" name="isErectileDysfunction" [(ngModel)]="PreOpFormData.isErectileDysfunction" [value]="true">
        <label for="cialis1">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="cialis2" name="isErectileDysfunction" [(ngModel)]="PreOpFormData.isErectileDysfunction" [value]="false">
        <label for="cialis2" >No</label>
      </div>
    </div>
  </div>
</div>
  <div class="wrap-box">
    <button class="btn-save">Submit</button>
  </div>
  </form>
