import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { RtmPainFormHeading } from '../rtm-pain.model';
import { RtmPainComponent } from '../rtm-pain.component';
import { RtmPainFormService } from '../rtm-pain.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-rtm-pain-verify-dob',
  templateUrl: './rtm-pain-verify-dob.component.html',
  styleUrls: ['./rtm-pain-verify-dob.component.scss']
})
export class RtmPainVerifyDobComponent implements OnInit {

  constructor(
    private rtmPainService: RtmPainFormService,
    private rtmPainForm: RtmPainComponent,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private auth: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe,
  ) { }

  todayDate = moment(new Date()).format('MMMM DD yyyy');
  id: string = '';
  ISInvalid: boolean = false;
  Isreview: boolean = false;
  dateofbirths: any = new UntypedFormControl(new Date());
  dateofbirth: string =  moment(new Date()).format('YYYY-MM-DD');
  reportingdate: string = moment(new Date()).format('YYYY-MM-DD');

  formHeading: RtmPainFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    this.GetHeading();
  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.dateofbirth = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }

  next() {
    this.rtmPainService.GetRtmPainPatient(this.id, this.reportingdate, this.dateofbirth).subscribe(
      (results) => {
        if (results != null || results.id != '') {
          this.auth.setItem('dateOfBirth', this.dateofbirth);
          this.rtmPainForm.onNextComponentClick();
        } else {
          this.ISInvalid = true;
        }
      },
      (error) => {
        if (error.status == 403) {
          this.router.navigate(['/questionnaires/rtm-pain/'+ this.id +'/thank-you']);
          // this.Isreview = true;
        } else {
          this.ISInvalid = true;
        }
      }
    );
  }
  GetHeading() {
    this.rtmPainService.GetRtmPainHeadingData(this.id).subscribe(
      (results: RtmPainFormHeading) => {
        this.formHeading = results;
      },
      (error) => {
      }
    );
  }
}
