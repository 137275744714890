import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; import { AuthenticationService } from '@app/authentication/authentication.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DailyAsthmaFormHeading } from '../daily-asthma-reporting.model';
import { AsthmaSelfReportingDailyComponent } from '../asthma-self-reporting-daily.component';
import { DailyAsthmaReportingService } from '../daily-asthma-reporting.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-daily-asthma-verify-dob',
  templateUrl: './daily-asthma-verify-dob.component.html',
  styleUrls: ['./daily-asthma-verify-dob.component.scss']
})
export class DailyAsthmaVerifyDobComponent implements OnInit {

  constructor(
    private AsthmaSelfReportingDailyComponent: AsthmaSelfReportingDailyComponent,
    private DailyAsthmaReportingService: DailyAsthmaReportingService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private auth: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe,
  ) { }

  todayDate = moment(new Date()).format('MMMM DD yyyy');
  id: string = '';
  ISInvalid: boolean = false;
  Isreview: boolean = false;
  dateofbirths: any = new UntypedFormControl(new Date());
  dateofbirth: string =  moment(new Date()).format('YYYY-MM-DD');
  reportingdate: string = moment(new Date()).format('YYYY-MM-DD');

  formHeading: DailyAsthmaFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    this.GetHeading();
  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.dateofbirth = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }

  next() {
    this.DailyAsthmaReportingService.GetDailyAsthmaPatient(this.id, this.reportingdate, this.dateofbirth).subscribe(
      (results) => {
        if (results != null || results.id != '') {
          this.auth.setItem('dateOfBirth', this.dateofbirth);
          this.AsthmaSelfReportingDailyComponent.onNextComponentClick();
        } else {
          this.ISInvalid = true;
        }
      },
      (error) => {
        if (error.status == 403) {
          this.router.navigate(['/questionnaires/asthma-self-reporting-daily/'+ this.id +'/thank-you']);
          // this.Isreview = true;
        } else {
          this.ISInvalid = true;
        }
      }
    );
  }
  GetHeading() {
    this.DailyAsthmaReportingService.GetHeadingData(this.id).subscribe(
      (results: DailyAsthmaFormHeading) => {
        this.formHeading = results;
      },
      (error) => {
      }
    );
  }

}
