import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { AnnualWellnessService } from '@app/annual-wellness-visit-form/annualwellness.service';
import { InvitePatient } from '@app/annual-wellness-visit-form/annualwellness.model';
import { Router,NavigationExtras  } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-invite-patients',
  templateUrl: './invite-patients.component.html',
  styleUrls: ['./invite-patients.component.scss']
})
export class InvitePatientsComponent implements OnInit {
  loading: boolean;
  form: UntypedFormGroup;
  currentTab = "invitePatient";
  formValue:string='';
  patientsrow: any[] = [{}];
  dateofbirth: string = '';
  dateOfBirths: any = new UntypedFormControl();

  constructor(private datePipe: DatePipe, private formbuilder: UntypedFormBuilder, private annualServive:AnnualWellnessService, private router:Router, private toastr:ToastrService,private location: Location) {
    this.form = this.formbuilder.group({
      patients: this.formbuilder.array([])
    });
  }
  InvitePatient:InvitePatient[]=[]

  ngOnInit(): void {
    this.addRow(); // Add one row initially
  }

  get patients() {
    return this.form.get('patients') as UntypedFormArray;
  }

  addRow() {
    const patient = this.formbuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(16)]],
      dateOfBirth: ['', Validators.required],
      form:[this.formValue]
    });

    this.patients.push(patient);
  }

  isFieldInvalid(index: number, field: string) {
    const control = (this.patients.controls[index] as UntypedFormGroup).get(field);
    return control.invalid && (control.dirty || control.touched);
  }

  updateSelectedForm(value:string){
    this.formValue = value;
  }

  onDateInput(event: MatDatepickerInputEvent<Date>, index: number) {
    const selectedDate = event.value;
    this.dateofbirth = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
    const patientControl = this.patients.at(index).get('dateOfBirth');
    patientControl.setValue(this.dateofbirth);
}

  submitForm() {
    if (this.form.valid) {
      // Process the form data
      this.InvitePatient = this.form.value.patients;
      this.InvitePatient.forEach((ele)=>{
        if (!ele.phoneNumber.startsWith('+')) {
        ele.phoneNumber = `+1${ele.phoneNumber.toString()}`;
        }
      })
      this.annualServive.InviteAWVPatient( this.InvitePatient).subscribe(
        (results) => {
          this.toastr.success('Patients are successfully invited', 'Successful');
          this.router.navigate(['/forms'], { state: { tabIndex: 1 } });
        },
        (error) => {
          this.toastr.error('Error while invite patient', 'Error');
        }
      );
    } else {
      // Mark all fields as touched to display validation messages
      this.patients.controls.forEach(control => {
        control.markAllAsTouched();
      });
    }
  }

  changeTab(targetTab) {
    this.currentTab = targetTab;
  }
  closeButton(){
    this.location.back();
  }
}
