
  <div class="">
    <div class="">
      <div><span class="text-20-bold-700 line-height-48">{{MonthlyFormHeading.formName}}</span></div>
      <div class="line-height-48">
        <span class="text-20-bold-700">Medical practice: </span>
        <span class="text-20-bold-400">{{MonthlyFormHeading.clinicName}}</span>
      </div>
      <div class="line-height-48">
        <span class="text-20-bold-700">Document created: </span>
        <span class="text-20-bold-400" *ngIf="MonthlyFormHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
        <span class="text-20-bold-400" *ngIf="MonthlyFormHeading.createdAt !== null">{{MonthlyFormHeading.createdAt | date: 'MMMM dd yyyy'}}</span>
      </div>
    </div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Note : </span>
      <span class="text-20-bold-400">Please report the values your care team advised you to track. Questions with * are mandatory.</span>
    </div>

  <div class="wrap-box" >
    <h2 class="blue-header">General</h2>
  </div>

      <div class="mt-3" >
        <div class="wrap-box">
          <label class="text-20-bold-600 label-font-14">In the past 4 weeks, how much of the time did your asthma keep you from getting as much done at work, school or at home?</label>
        </div>
        <div class="wrap-box">
          <div class="wrap-radio">
            <input type="radio" name="asthmaImpact" id="asthmaImpact1" value="ALL_OF_THE_TIME" [(ngModel)]="monthlyAsthmaData.asthmaImpact">
            <label for="asthmaImpact1">All of the time</label>
          </div>
        </div>
        <div class="wrap-box">
          <div class="wrap-radio">
            <input type="radio" name="asthmaImpact" id="asthmaImpact2" value="MOST_OF_THE_TIME" [(ngModel)]="monthlyAsthmaData.asthmaImpact">
            <label for="asthmaImpact2">Most of the time</label>
          </div>
        </div>
        <div class="wrap-box">
          <div class="wrap-radio">
            <input type="radio" name="asthmaImpact" id="asthmaImpact3" value="SOME_OF_THE_TIME" [(ngModel)]="monthlyAsthmaData.asthmaImpact">
            <label for="asthmaImpact3">Some of the time</label>
          </div>
        </div>
        <div class="wrap-box">
          <div class="wrap-radio">
            <input type="radio" name="asthmaImpact" id="asthmaImpact4" value="LITTLE_OF_THE_TIME" [(ngModel)]="monthlyAsthmaData.asthmaImpact">
            <label for="asthmaImpact4">Little of the time</label>
          </div>
        </div>
        <div class="wrap-box">
          <div class="wrap-radio">
            <input type="radio" name="asthmaImpact" id="asthmaImpact5" value="NONE_OF_THE_TIME" [(ngModel)]="monthlyAsthmaData.asthmaImpact">
            <label for="asthmaImpact5">None of the time</label>
          </div>
        </div>

  <div class="mt-3" >
    <div class="wrap-box">
        <label class="text-20-bold-600 label-font-14">During the past 4 weeks, how often have you had shortness of breath?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="breathShortnessFrequency" id="breathFrequency11" value="A_2_3_TIMES_A_DAY_OR_MORE" [(ngModel)]="monthlyAsthmaData.breathShortnessFrequency">
        <label for="breathFrequency11">2-3 times a day or more</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="breathShortnessFrequency" id="breathFrequency12" value="ONCE_A_DAY" [(ngModel)]="monthlyAsthmaData.breathShortnessFrequency">
        <label for="breathFrequency12">Once a day</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="breathShortnessFrequency" id="breathFrequency13" value="A_3_6_TIMES_A_WEEK" [(ngModel)]="monthlyAsthmaData.breathShortnessFrequency">
        <label for="breathFrequency13">3-6 times a week</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="breathShortnessFrequency" id="breathFrequency14" value="ONCE_A_WEEK_OR_LESS" [(ngModel)]="monthlyAsthmaData.breathShortnessFrequency">
        <label for="breathFrequency14">Once a week or less</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="breathShortnessFrequency" id="breathFrequency15" value="NOT_AT_ALL" [(ngModel)]="monthlyAsthmaData.breathShortnessFrequency">
        <label for="breathFrequency15">Not at all</label>
      </div>
    </div>
</div>

  <div class="mt-3" >
    <div class="wrap-box">
        <label class="text-20-bold-600 label-font-14">During the past 4 weeks, how often did your symptoms
          (wheezing, coughing, shortness of breath, chest tightness or pain) wake you up at night or earlier than usual in the morning?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsFrequency" id="symptomsFrequency11" value="A_2_3_TIMES_A_DAY_OR_MORE" [(ngModel)]="monthlyAsthmaData.symptomsFrequency">
        <label for="symptomsFrequency11">At least 4 nights a week</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsFrequency" id="symptomsFrequency12" value="ONCE_A_DAY" [(ngModel)]="monthlyAsthmaData.symptomsFrequency">
        <label for="symptomsFrequency12">2-3 nights a week</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsFrequency" id="symptomsFrequency13" value="A_3_6_TIMES_A_WEEK" [(ngModel)]="monthlyAsthmaData.symptomsFrequency">
        <label for="symptomsFrequency13">Once a week</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsFrequency" id="symptomsFrequency14" value="ONCE_A_WEEK_OR_LESS" [(ngModel)]="monthlyAsthmaData.symptomsFrequency">
        <label for="symptomsFrequency14">Less than once a week</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsFrequency" id="symptomsFrequency15" value="NOT_AT_ALL" [(ngModel)]="monthlyAsthmaData.symptomsFrequency">
        <label for="symptomsFrequency15">Not at all</label>
      </div>
    </div>
  </div>

  <div class="mt-3" >
    <div class="wrap-box">
        <label class="text-20-bold-600 label-font-14">During the past 4 weeks, how often have you used your rescue inhaler or nebulizer medication (such as albuterol)?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="rescueMedicationFrequency" id="rescueMedication11" value="A_3_OR_MORE_TIMES_A_DAY" [(ngModel)]="monthlyAsthmaData.rescueMedicationFrequency">
        <label for="rescueMedication11">3 or more times a day</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="rescueMedicationFrequency" id="rescueMedication12" value="A_1_2_TIMES_A_DAY" [(ngModel)]="monthlyAsthmaData.rescueMedicationFrequency">
        <label for="rescueMedication12">1-2 times a day</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="rescueMedicationFrequency" id="rescueMedication13" value="A_2_3_TIMES_A_WEEK" [(ngModel)]="monthlyAsthmaData.rescueMedicationFrequency">
        <label for="rescueMedication13">2-3 times a week</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="rescueMedicationFrequency" id="rescueMedication14" value="ONCE_A_WEEK_OR_LESS" [(ngModel)]="monthlyAsthmaData.rescueMedicationFrequency">
        <label for="rescueMedication14">Once a week or less</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="rescueMedicationFrequency" id="rescueMedication15" value="NOT_AT_ALL" [(ngModel)]="monthlyAsthmaData.rescueMedicationFrequency">
        <label for="rescueMedication15">Not at all</label>
      </div>
    </div>
  </div>

  <div class="mt-3" >
    <div class="wrap-box">
        <label class="text-20-bold-600 label-font-14">How would you rate your symptoms control during the past 4 weeks?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsRate" id="symptomsRate11" value="NOT_CONTROLLED" [(ngModel)]="monthlyAsthmaData.symptomsRate">
        <label for="symptomsRate11">Not controlled at all</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsRate" id="symptomsRate12" value="POORLY_CONTROLLED" [(ngModel)]="monthlyAsthmaData.symptomsRate">
        <label for="symptomsRate12">Poorly controlled</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsRate" id="symptomsRate13" value="SOMEHOW_CONTROLLED" [(ngModel)]="monthlyAsthmaData.symptomsRate">
        <label for="symptomsRate13">Somehow controlled</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsRate" id="symptomsRate14" value="WELL_CONTROLLED" [(ngModel)]="monthlyAsthmaData.symptomsRate">
        <label for="symptomsRate14">Well controlled</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="symptomsRate" id="symptomsRate15" value="COMPLETELY_CONTROLLED" [(ngModel)]="monthlyAsthmaData.symptomsRate">
        <label for="symptomsRate15">Completely controlled</label>
      </div>
    </div>
  </div>
<div class="wrap-box">
  <button class="btn-save" (click)="save()">Submit</button>
</div>
  </div>
